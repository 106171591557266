import React from 'react';

export default (
  <svg
    width="140px"
    height="140px"
    viewBox="0 0 240 240"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <g transform="translate(20.000000, 20.000000)" fillRule="nonzero">
      <polygon
        fill="white"
        points="112.4 48.7 88.2 48.7 53.2 139.6 75.1 139.6 80.8 124.2 113.9 124.2 107.9 107.2 86.1 107.2 100.3 68.1 125.5 139.6 147.4 139.6"
      />
      <path
        style={{
          transformOrigin: '100px 100px',
          animation: 'spin 3s cubic-bezier(0.75, 0, .25, 1) infinite',
        }}
        id="logo-spin"
        fill="white"
        d="M196.3,73 C196.2,72.7 196.2,72.4 196,72.1 C196,72 195.9,71.8 195.9,71.8 C195.1,70.1 193.4,68.9 191.4,68.9 C188.6,68.9 186.4,71.1 186.4,73.9 C186.4,74.5 186.5,75 186.7,75.5 L186.7,75.5 C188.9,83.4 190,91.6 190,100 C190,124 180.6,146.6 163.6,163.6 C146.6,180.6 124,190 100,190 C76,190 53.4,180.6 36.4,163.6 C19.4,146.6 10,124 10,100 C10,76 19.4,53.4 36.4,36.4 C53.4,19.4 76,10 100,10 C121.5,10 141.8,17.5 158,31.2 C157.5,32.6 157.2,34.1 157.2,35.7 C157.2,42.5 162.7,47.9 169.4,47.9 C176.2,47.9 181.6,42.4 181.6,35.7 C181.6,29 176.1,23.5 169.4,23.5 C167.9,23.5 166.5,23.8 165.2,24.2 C147.7,9.1 124.9,0 100,0 C44.8,0 0,44.8 0,100 C0,155.2 44.8,200 100,200 C155.2,200 200,155.2 200,100 C200,90.7 198.7,81.6 196.3,73 Z"
      />
    </g>
    <rect fill="transparent" x="0" y="0" width="240" height="240" />
  </svg>
);
